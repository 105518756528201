import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/request/token'
Vue.use(VueRouter)
//push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}
let router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/view/home/index'),
      meta: {
        topShow: true,
        footShow: true
      }
    },
    {
      path: '/404',
      name: 'manufacturer',
      component: () => import('@/view/login/404'),
      meta: {
        topShow: true,
        footShow: true
      }
    },
    {
      path: '/rfq',
      name: 'RFQ',
      component: () => import('@/view/rfq/index'),
      meta: {
        topShow: true,
        footShow: true
      }
    },
    {
      path: '/part/:keyword',
      name: 'list',
      component: () => import('@/view/list/index'),
      meta: {
        topShow: true,
        footShow: true
      }
    },
    {
      path: '/business', 
      name:'about',
      component: () => import('@/view/about/business'),
      meta:{
        topShow:true,
        footShow:true
      }
    },
    {
      path: '/privacy', 
      name:'privacy',
      component: () => import('@/view/about/privacy'),
      meta:{
        topShow:true,
        footShow:true
      }
    },
    {
      path: '/terms', 
      name:'terms',
      component: () => import('@/view/about/terms'),
      meta:{
        topShow:true,
        footShow:true
      }
    },
    {
      path: '/about', 
      name:'about',
      component: () => import('@/view/about/index'),
      meta:{
        topShow:true,
        footShow:true
      }
    },
    {
      path: '/categories',
      name: 'electronic',
      component: () => import('@/view/products/index'),
      meta: {
        topShow: true,
        footShow: true
      }
    },
    {
      path: '/secondLevel',
      name: 'secondLevel',
      component: () => import('@/view/products/secondLevel'),
      meta: {
        topShow: true,
        footShow: true
      }
    },
    {
      path: '/personal',
      name: 'me',
      component: () => import('@/view/account/index'),
      meta: {
        topShow: true
      }
    },
    {
      path: '/landingPage',
      name: 'landingPage',
      component: () => import('@/view/about/landingPage')
    },
    {
      path: '*',
      redirect: '/rfq',
      hidden: true
    }
  ]
})
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next()
  Vue.prototype.$previousPath = from.path;
  //获取token
  let token = getToken()
  //token存在则为登录状态
  if (token) {
    //已登录不能再去注册或登录
    if (to.path === '/login') {
      next()
    } else {
      try {
        next()
      } catch (error) {
        next('/login')
      }
    }
  } else {
    let toPath = to.path
    //判断要去的地方是否个人中心
    if (toPath.indexOf('personal') != -1) {
      //将原本要去的地址当作query参数拼接到地址上
      next(`/`)
      sessionStorage.setItem('loginType',2)
    } else {
      next()
      window.scrollTo(0, 0)
    }
  }
})
export default router
