import { get, post, get1, post1 } from '../request/request'

function registerUser(data) {
    return post1('/register', data)
}
function search(key, type, pre) {
    return get1(`/search?keywords=${key}&group_type=${type}&pre=${pre}`)
}
function hot_search() {
    return get1(`/hot_search?type=24`)
}
function login(data) {
    return post1('/login', data)
}
function logout() {
    return get1('/user/logout')
}
function homeData() {
    return get('/home')
}
function cat() {
    return get('/cat')
}
function setUser(data) {
    return post1('/user/set_info', data)
}
function getUser() {
    return get1('/user/info')
}
function getRFQ(data) {
    return get1('/user/rfqs', data)
}
function setPassword(data) {
    return post1('/user/password', data)
}
function setRfq(data) {
    return post1('/rfq', data)
}
function setRFQ(data) {
    return post1('/user/rfq', data)
}
function filter(data) {
    return post(`/filter`, data)
}
function jump(data) {
    return post1(`/jump`, data)
}
export default {
    registerUser,
    login,
    logout,
    homeData,
    cat,
    setRfq,
    setRFQ,
    setUser,
    getUser,
    getRFQ,
    setPassword,
    filter,
    search,
    hot_search,
    jump
}