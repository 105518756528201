<template>
  <div id="app">
    <div class="top-fixed">
      <div style="visibility: hidden;" :class="scrollTop > 0 ? 'occupy' : ''"></div>
      <div class="top">
        <div class="top_nav">
          <div class="left_box">
            <span>
              <i class="el-icon-message"></i>
              info@bomchips.com
            </span>
            <!-- <span>
            <i class="el-icon-phone"></i>
            1234-1223-1234
          </span> -->
          </div>
          <div class="right_user">
            <router-link to="/rfq">
              <i class="el-icon-tickets"></i>
              RFQ
            </router-link>
            <span class="btn" @click="setMask">
              <i class="el-icon-user"></i>
              {{ token ? $t('app.account') : $t('app.login') }}
            </span>
          </div>
        </div>
        <div class="search_code content" v-show="searchShow">
          <router-link to="/" class="logo">
            <img src="./assets/img/home/Group (1).png" alt="">
          </router-link>
          <div class="search">
            <el-autocomplete class="inline-input" v-model.trim="state" :fetch-suggestions="querySearch"
              :placeholder="$t('app.searchTxt')" :debounce="10" :trigger-on-focus="false" ref="cautocomplete" @select="handleSelect"
              @keyup.enter.native="loadAll(state, 0)">
            </el-autocomplete>
            <div class="btn" @click="loadAll(state, 0)">
              {{ $t('app.search') }}
            </div>
          </div>
          <div class="all_send" @click="goChild" v-if="sendShow">
            {{ $t('list.all') }}
          </div>
        </div>
      </div>
    </div>
    <router-view v-if="isReload" ref="child"></router-view>
    <div class="foot_box">
      <div class="content">
        <div class="foot">
          <div class="left_logo">
            <img src="./assets/img/home/Group.png" alt="">
          </div>
          <div class="right_data">
            <ul>
              <li>{{ $t('app.company') }}</li>
              <li>
                <router-link to="/about">{{ $t('app.about') }}</router-link>
              </li>
              <li>
                <router-link to="/about">{{ $t('app.contact') }}</router-link>
              </li>
              <li>
                <router-link to="/business">{{ $t('app.business') }}</router-link>
              </li>
            </ul>
            <ul>
              <li>{{ $t('app.policy') }}</li>
              <li>
                <router-link to="/privacy">{{ $t('app.privacy') }}</router-link>
              </li>
              <li>
                <router-link to="/terms">{{ $t('app.terms') }}</router-link>
              </li>
            </ul>
            <ul>
              <li>{{ $t('app.service') }}</li>
              <li>
                <router-link to="/rfq">RFQ</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="company_news">
          © 2023 All Rights Reserved By bomchips.
          <p>
            <i class="el-icon-location"></i>
          Shop 185, G/F, Hang Wai Centre, 6 Kin Tai Street, Tuen Mun, New Territories, Hong Kong
          </p>
        </div>
      </div>
    </div>
    <div class="mask" v-if="maskShow" @click="closeOverlay">
    </div>
    <div class="mask-box" v-if="maskShow">
      <div class="login content">
        <Logins @close="closeOverlay" :type="loginType"></Logins>
      </div>
    </div>
    <div class="location btn" @click="getIndex('top_nav')">
      <img src="./assets/img/home/Group 9116.png" alt="">
    </div>
  </div>
</template>

<script>
import http from "./api";
import login from "./view/login/index";
import { getToken } from "./request/token";
export default {
  metaInfo() {
    return {
      meta: [
        {
          charset: "utf-8"
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        },
        {
          name: "description",
          content: 'bomchips is a free to use electronic component price comparison search engine. No login or subscription is required to find and buy parts. Search from 100+ electronics distributors.'
        },
        {
          name: "keywords",
          content: 'bomchips,electronic component price, distributor stock, buy electronic parts, IC, distributor price'
        }
      ]
    }
  },
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  components: { "Logins": login },
  data() {
    return {
      isReload: true,
      state: '',
      searchShow: true,
      maskShow: false,
      domType: '',
      token: '',
      loginType: 0,
      pageType: 0,
      scrollTop: 0,
      restaurants: [],
      sendShow: false
    }
  },
  watch: {
    '$route'(to, from) { //监听路由是否变化
      if (this.$route.name === 'home') { //跳转到哪个页面
        this.searchShow = false;
        this.pageType = 1;
        this.state = '';
      } else {
        this.searchShow = true;
        this.pageType = 0;
        this.state = sessionStorage.getItem('search');
      }
    }
  },
  created() {
    this.token = getToken();
    this.loginType = sessionStorage.getItem('loginType');
    if (this.loginType) {
      this.setMask(this.loginType)
    }
  },
  mounted() {
    this.setUrl();
    this.setTopNav();
  },
  methods: {
    setUrl() {
      const reful = document.referrer
      const url = location.href
      let str = ''
      if (reful.indexOf('google') > -1 || url.indexOf('gclid') > -1) {
        str = 'google'
      } else if (url.indexOf('yclid') > -1) {
        str = 'yandex'
      }
      if (url.indexOf('resource=') > -1) {
        const arr = url.split('resource=')
        if (arr.length >= 2) {
          str = arr[1].split('&')[0]
        }
      }
      this.$store.commit('referer', str)
    },
    getIndex() {
      document.querySelector('.top_nav').scrollIntoView({
        behavior: "smooth",
        // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
        block: "center",
        // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        inline: "nearest"
        // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      })
    },
    querySearch(queryString, cb) {
      this.$refs.cautocomplete.activated = false;
      if (queryString.length > 2) {
        this.loadAll('', 1)
        var restaurants = this.restaurants.map(item => {
          return { value: item, label: item };
        });
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        setTimeout(() => {
          // 调用 callback 返回建议列表的数据
          cb(results);
        }, 1000);
        this.$refs.cautocomplete.activated = true;
      }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll(val, type) {
      this.$refs.cautocomplete.activated = false;
      if (this.state != "" || val != "") {
        let value = this.state != "" ? this.state : val;
        if (type === 1) {
          http.search(value, 0, type).then(res => {
            if (res.code == 200) {
              this.restaurants = res.data;
            }
          })
        } else {
          sessionStorage.setItem('search',value)
          this.$router.push('/part/' + value)
        }
      }
    },
    handleSelect(item) {
      this.loadAll(item, 0)
    },
    setMask() {
      if (this.token) {
        this.$router.push({
          path: '/personal'
        })
      } else {
        this.maskShow = !this.maskShow;
      }
    },
    closeOverlay() {
      this.maskShow = false;
      sessionStorage.removeItem('loginType');
      location.reload();
    },
    setTopNav() {
      window.addEventListener('scroll', this.handScroll, true);
    },
    handScroll() {
      this.scrolltop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      if (document.querySelector(".top-fixed") != null) {
        if (this.scrolltop >= 400) {
          this.searchShow = true;
          this.sendShow = true;
          document.querySelector(".top-fixed").classList.add("fixed-nav");
          //如果滑动到导航就绑定样式让它吸顶
        } else {
          if (this.pageType === 1) {
            this.searchShow = false;
          }
          this.sendShow = false;
          document.querySelector(".top-fixed").classList.remove("fixed-nav");
        }
      }
    },
    goChild(){
      this.$refs.child.goAll();
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFBFF;
  font-size: 14px;
  .top-fixed {
    width: 100%;
    z-index: 999;
    /* 兼容 */
    position: -webkit-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    /* 粘性定位*/
    position: sticky;
    background-color: #fff;
    top: 0;
    left: 0;
  }
  .occupy{
    height: 160px;
  }
  .fixed-nav {
    transition: all .2s;
    animation: example .3s linear;
    animation-fill-mode: forwards;
    background-color: #fff;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  }
  .top {
    .top_nav {
      background: url('./assets/img/home/Group 1.png') no-repeat center;
      background-size: cover;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 14px;
      justify-content: space-around;

      .left_box {
        color: #fff;

        i {
          color: #FB9E49;
        }

        span:nth-child(1) {
          margin-right: 78px;
        }
      }

      .right_user {
        color: #fff;

        a {
          margin-right: 16px;
          color: #fff;
        }
      }
    }

    .search_code {
      padding: 20px 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search {
        height: 50px;
        display: flex;
        align-items: center;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);

        .el-autocomplete {
          width: 92%;
          height: 100%;
        }

        /deep/ .el-input {
          height: 100%;
        }

        /deep/ .el-input__inner {
          border: none;
          height: 100%;
        }

        .btn {
          display: inline-block;
          font-size: 18px;
          padding: 15px 24px;
          color: #fff;
          background-color: #FB9E49;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  .foot_box {
    background-color: #161921;
    height: 352px;
    width: 100%;
    padding-top: 56px;

    .foot {
      display: flex;
      align-items: baseline;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 88px;
      margin-bottom: 6px;
    }

    .left_logo {
      display: inline-block;
      width: 258px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right_data {
      margin-left: 203px;
      display: flex;
      flex: 1;
      justify-content: space-between;

      ul {
        li {
          color: #999;
          font-size: 14px;
          margin-bottom: 12px;

          a {
            color: #999;
          }

          &:nth-child(1) {
            color: #fff;
            font-weight: 800;
            font-size: 18px;
            margin-bottom: 19px;
          }
        }
      }
    }

    .company_news {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #D9D9D9;
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);

    .close {
      color: #fff;
      font-size: 61px;
      position: fixed;
      top: 26px;
      right: 58px;
      z-index: 100;
    }
  }

  .mask-box {
    position: relative;

    .login {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
    }
  }

  .location {
    width: 38px;
    height: 38px;
    position: fixed;
    bottom: 40px;
    right: 100px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and(min-width: 1220px) {
    .search_code {
      .logo {
        display: block;
        width: 200px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .search {
        width: 60%;
      }
    }
    .company_news {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #D9D9D9;

      i {
        margin-left: 25px;
      }
    }
  }
  @media screen and(max-width: 1200px) {
    .search_code{
      flex-direction: column;
      .logo{
        display: none;
      }
      .search{
        width: 100%;
      }
      .all_send{
        width: 100%;
        background-color: #FB9E49;
        padding: 10px 0;
        color: #fff;
        text-align: center;
        margin-top: 10px;
      }
    }
    .foot_box{
      height: 100%;
    }
    .foot{
      .left_logo{
        display: none;
      }
      .right_data{
        margin-left: 0;
        flex-direction: column;
        padding: 0 16px;
      }
    }
    .company_news{
      flex-direction: column;
      p{
        text-align: left;
        i{
          margin-left: 0;
        }
      }
    }
  }
}
</style>
