import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    resource: ''
  },
  getters: {
    
  },
  mutations: {
    referer(state, data) {
      const reful = data
      state.resource = reful
    }
  },
  actions: {
    
  },
  plugins: [createPersistedState(
    { storage: window.sessionStorage }
  )]
})
