import axios from 'axios'
import router from '../routes'
import { getToken, removeToken } from '../request/token'
import { Loading } from "element-ui";

let loading;
let loadingNum = 0;

function startLoading() {
    if (loadingNum == 0) {
        loading = Loading.service({
            lock: true,
            background: 'rgba(255,255,255,0.5)',
        })
    }
    //请求数量加1
    loadingNum++;
}
function endLoading() {
    //请求数量减1
    loadingNum--
    if (loadingNum <= 0) {
        loading.close()
    }
}

// 设置请求头
const instance = axios.create({
    timeout: 8000,//设置网络请求超时时间
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Accept': 'application/json'
    },
    retryInterval: 10000 /* 设置请求失败之后再次发起请求的间隔 */
});
const instance1 = axios.create({
    timeout: 8000,//设置网络请求超时时间
    baseURL: process.env.VUE_APP_API_URL1,
    headers: {
        'Accept': 'application/json'
    },
    retryInterval: 10000 /* 设置请求失败之后再次发起请求的间隔 */
});
instance.interceptors.request.use(
    (config) => {
        startLoading();
        let token = sessionStorage.getItem("token");
        token && (config.headers.Authorization = 'bearer  ' + token);
        return config;
    },
    (error) => {
    }
);
// 添加响应拦截器
instance.interceptors.response.use(
    (response) => {
        endLoading();
        return response;
    },
    (error) => {
        endLoading();
        console.log(error.response)
        // 捕获 401 错误并处理过期的 token
        if (error.response.status === 401) {
            removeToken();
        }
    }
);
instance1.interceptors.request.use(
    (config) => {
        // startLoading();
        let token = sessionStorage.getItem("token");
        token && (config.headers.authorization = 'Bearer ' + token);
        return config;
    },
    (error) => {
    }
);
// 添加响应拦截器
instance1.interceptors.response.use(
    (response) => {
        // endLoading();
        return response;
    },
    (error) => {
        // endLoading();
        // 捕获 401 错误并处理过期的 token
        if (error.response.status === 401) {
            sessionStorage.setItem('loginType', 2)
            router.push("/");
            removeToken()
        }
    }
);
export function get(url, params) {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: params
        }).then(res => {
            if (res == undefined) {
                // router.push("/login");
            } else {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}
export function post(url, data) {
    return new Promise((resolve, reject) => {
        instance.post(url, data).then(res => {
            if (res == undefined) {
                // router.push("/login");
            } else {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}
export function get1(url, params) {
    return new Promise((resolve, reject) => {
        instance1.get(url, {
            params: params
        }).then(res => {
            if (res == undefined) {
                // router.push("/login");
            } else {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}
export function post1(url, data) {
    return new Promise((resolve, reject) => {
        instance1.post(url, data).then(res => {
            if (res == undefined) {
                // router.push("/login");
            } else {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}
export default { instance, instance1 }