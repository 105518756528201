<template>
  <div class="login">
    <div class="centent-fox">
      <div class="centent-left">
        <img src="../../assets/img/home/login.png">
      </div>
      <div class="centent-right">
        <p class="closes btn" @click="setClose"><i class="el-icon-close close"></i></p>
        <el-form v-if="loginOf === $t('login.register')" :model="loginForm" :rules="register" ref="loginForm"
          label-position="left" class="demo-ruleForm">
          <div class="title">
            <p>{{ $t('login.loginTitle')}}</p>
            <p>{{ $t('login.loginTitle1')}}</p>
          </div>
          <div class="form-group">
            <el-form-item :label="$t('list.email')" prop="email">
              <el-input v-model="loginForm.email" :placeholder="$t('list.please') + $t('list.email')"></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item :label="$t('login.password')" prop="password">
              <el-input v-model="loginForm.password" @keyup.enter.native="goLogin('loginForm')" show-password></el-input>
            </el-form-item>
          </div>
          <div class="submit btn" @click="goLogin('loginForm')">{{ $t('login.signIn')}}</div>
        </el-form>
        <el-form v-else :model="registerForm" :rules="register" ref="registerForm" label-position="left"
          class="demo-registerForm">
          <div class="title">
            <p>{{ $t('login.registerTitle')}}</p>
            <p>{{ $t('login.registerTitle1')}}</p>
          </div>
          <div class="form-box">
            <div class="form-group">
              <div class="fox">
                <el-form-item :label="$t('list.email')" prop="email">
                  <el-input v-model="registerForm.email"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-group">
              <div class="fox">
                <el-form-item :label="$t('login.password')" prop="password">
                  <el-input v-model="registerForm.password" show-password  @keyup.enter.native="goRegister('registerForm')"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="submit btn" @click="goRegister('registerForm')">{{ $t('login.create')}}</div>
        </el-form>
        <div class="tip btn">
          {{ text }} <p @click="setRegister(loginOf)">{{ loginOf }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from '../../api'
import { setToken } from "../../request/token";
export default {
  data() {
    return {
      text: this.$t('login.text'),
      loginOf: this.$t('login.register'),
      loginForm: {
        email: '',
        password: ''
      },
      registerForm: {
        email: '',
        password: '',
        password_confirmation: '',
        contact_name: '',
        company_name: '',
        country: '',
        address: ''
      },
      register: {
        email: [
          { required: true, message: this.$t('list.please') + this.$t('list.email')},
          { pattern: /^(([0-9a-zA-Z]+)|([0-9a-zA-Z]+[_.0-9a-zA-Z-]*[0-9a-zA-Z-]+))+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, message: this.$t('list.please') + this.$t('list.correct') +this.$t('list.email'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('list.please') + this.$t('login.password'), trigger: 'blur' },
          { min: 6, message: this.$t('login.error'), trigger: 'blur' }
        ]
      }
    }
  },
  props:['type'],
  created() {
    if (this.type == 1) {
      this.setRegister(this.loginOf)
    }
  },
  methods: {
    setRegister(txt) {
      if (txt == this.$t('login.register')) {
        this.text = this.$t('login.text1');
        this.loginOf = this.$t('login.login');
      } else {
        this.text = this.$t('login.text');
        this.loginOf = this.$t('login.register');
      }
    },
    goLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          http.login(this.loginForm).then(res => {
            if (res.code === 200) {
              setToken(res.data.token)
              this.$message({
                message: res.msg,
                type: 'success'
              });
              this.$emit('close')
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          http.registerUser(this.registerForm).then(res => {
            if (res.code === 200) {
              this.$message({
                message: res.msg,
                type: 'success'
              });
              this.$emit('close')
              this.loginOf = "Register here";
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    setClose() {
      this.$emit('close');
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  width: 90%;
  height: 630px;
  background-color: #fff;
  border-radius: 5px;

  .centent-fox {
    width: 100%;
    border-radius: 18px;
    display: flex;
    align-items: center;

    .tip {
      width: 50%;
      display: inline-block;
      font-size: 14px;
      text-align: left;
      margin-top: 18px;
      color: #000;

      p {
        display: inline-block;
        color: #FB9E49;
      }
    }

    .centent-left {
      img {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    .centent-right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .closes {
        position: absolute;

        i {
          font-size: 30px;
        }
      }

      .demo-registerForm {

        .form-box {
          overflow: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(#FB9E49,0.3);
          }
        }

        /deep/ .el-form-item__content {
          margin-left: 0 !important;

          .el-input {
            .el-input__inner {
              border: none;
              height: 40px;
              border-bottom: 1px solid #D9D9D9;
            }
          }
        }

        .submit {
          height: 40px;
          line-height: 40px;
          font-size: 16px;
        }
      }

      .title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 60px;

        p:nth-child(2) {
          font-weight: normal;
          font-size: 14px;
          color: #999999;
          margin-top: 10px;
        }
      }

      .demo-ruleForm {

        .form-group {

          /deep/ .el-form-item__content {
            margin-left: 0 !important;

            .el-input {
              .el-input__inner {
                border: none;
                height: 40px;
                border-bottom: 1px solid #D9D9D9;
              }
            }
          }
        }
      }

      .submit {
        background: #FB9E49;
        color: #fff;
        border: none;
        width: 100%;
        height: 47px;
        line-height: 47px;
        text-align: center;
        display: block;
        font-size: 22px;
        border-radius: 5px;
      }
    }
  }

  .register {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 750px) {
  .centent-right {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .closes {
        position: absolute;
        top: 36px;
        right: 30px;

        i {
          font-size: 30px;
        }
      }

      .demo-registerForm {
        width: 70%;
        .form-box {
          overflow: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(#FB9E49,0.3);
          }
        }

        /deep/ .el-form-item__content {
          margin-left: 0 !important;

          .el-input {
            .el-input__inner {
              border: none;
              height: 40px;
              border-bottom: 1px solid #D9D9D9;
            }
          }
        }

        .submit {
          height: 40px;
          line-height: 40px;
          font-size: 16px;
        }
      }

      .title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 60px;

        p:nth-child(2) {
          font-weight: normal;
          font-size: 14px;
          color: #999999;
          margin-top: 10px;
        }
      }

      .demo-ruleForm {
        width: 70%;

        .form-group {
          margin: 30px 0;

          /deep/ .el-form-item__content {
            margin-left: 0 !important;

            .el-input {
              .el-input__inner {
                border: none;
                height: 40px;
                border-bottom: 1px solid #D9D9D9;
              }
            }
          }
        }
      }

      .submit {
        background: #FB9E49;
        color: #fff;
        border: none;
        width: 100%;
        height: 47px;
        line-height: 47px;
        text-align: center;
        display: block;
        font-size: 22px;
        border-radius: 5px;
      }
    }
}
@media screen and (max-width: 750px) {
  .login{
    width: 80%;
    height: auto;
  }
  .centent-fox{
    flex-direction: column;
    width: 100%;
    .centent-left{
      width: 100%;
      height: 200px;
      img{
        width: 100%;
      }
    }
    .centent-right{
      padding: 10px 16px;
      width: 100%;
      .demo-ruleForm{
        width: 100%;
        .title{
          margin-bottom: 10px;
          color: #fff;
          p:nth-child(2){
            color: #fff;
          }
        }
        .form-group{
          margin: 15px 0;
        }
      }
      .tip{
        width: 100%;
        text-align: center;
      }
      .closes{
        top: 40px;
        right: 20px;
        color: #fff;
      }
    }
  }
}
</style>