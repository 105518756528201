export const setToken = (token)=>{
    sessionStorage.setItem("token",token)
}
//获取token
export const getToken = function(){
    return sessionStorage.getItem('token')
}
//删除token
export const removeToken = function(){
    sessionStorage.removeItem("token")
}