import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
// 注册i18n实例并引入语言文件，文件格式等下解析
const i18n = new VueI18n({
    silentTranslationWarn: true,
    locale: process.env.VUE_APP_LANG ? process.env.VUE_APP_LANG : 'EN',  //默认显示为英文
    messages: {
        'RU': require('./ru.json'),
        'EN': require('./en.json')
    }
})
export default i18n