import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from "../src/store/index";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import i18n from "@/lang/index";
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
import './assets/base.css'
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './vant';
import MetaInfo from 'vue-meta-info';
Vue.use(ElementUI);
Vue.use(MetaInfo)
new Vue({
  render: h => h(App),
  i18n,
  router,
  store
}).$mount('#app')
